<template>
  <EditEntity
    :fields="fields"
    :save-url="saveUrl"
    :get-url="getUrl"
    :id="$route.params.id"
    @updateIsDataSaved="updateIsDataSaved"
  ></EditEntity>
</template>
<script>
import { defineComponent } from "vue";
import EditEntity from "@/components/EditEntity";

export default defineComponent({
  name: "mod-translation",
  components: {
    EditEntity,
  },
  data: function () {
    return {
      saveUrl: "api/admin/mod-translation/save",
      getUrl: "admin/mod-translation/",
      isDataSaved: true,

      fields: [
        {
          value: "originalDescription",
          name: "ORIGINAL Description",
          readonly: true,
          type: "textarea-i18n",
        },

        {
          value: "translation",
          name: "Description",
          type: "textarea-i18n",
        },
        {
          name: "Contact",
          value: "contact",
        },
      ],
    };
  },

  mounted() {
    window.addEventListener("beforeunload", this.beforeUnload);
  },

  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnload); // Очистите обработчик, чтобы избежать утечек памяти
  },

  beforeRouteLeave(to, from, next) {
    if (!this.isDataSaved) {
      const answer = window.confirm(
        "У вас есть несохраненные изменения. Вы уверены, что хотите уйти?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    beforeUnload(event) {
      if (!this.isDataSaved) {
        const message =
          "У вас есть несохраненные изменения. Вы уверены, что хотите покинуть страницу?";
        event.returnValue = message; // Стандартное сообщение для разных браузеров
        return message; // Некоторые браузеры требуют возврата сообщения
      }
    },
    updateIsDataSaved: function (newValue) {
      this.isDataSaved = newValue;
    },
  },
});
</script>
